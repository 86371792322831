import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import { Slide } from "react-reveal"
import deliveringImpactData from "data/deliveringImpactData"
import IconHex from "components/common/IconHex"
import styled from "styled-components"
import vars from "styles/variables"
import Image from "components/common/Image"

function DeliveringImpactContent(props) {
  const [activeId, setActiveId] = useState(null)

  const handleIconClick = id => {
    if (id === activeId) return setActiveId(null)
    setActiveId(id)
  }

  return (
    <>
      <Row>
        {deliveringImpactData.map(item => (
          <Col xs="3" key={item.id}>
            <Slide bottom>
              <IconHex
                text={item.title}
                iconPath={item.iconPath}
                onClick={() => handleIconClick(item.id)}
                isActive={item.id === activeId ? true : false}
              />
            </Slide>
          </Col>
        ))}
      </Row>
      {activeId && (
        <StyledDeliveringImpactContent>
          {getContent(activeId)}
        </StyledDeliveringImpactContent>
      )}
    </>
  )
}

export default DeliveringImpactContent

const StyledDeliveringImpactContent = styled.div`
  background: ${vars.grey_200};
  padding: 10rem 2rem 2rem 2rem;
  margin-top: -135px;
`

function getContent(id) {
  switch (id) {
    case 1:
      return (
        <Row>
          <Col xs="12" lg="7">
            <h3>Tackling Domestic Abuse</h3>
            <p>
              Last year we signed up to the Chartered Institute of Housing (CIH)
              ‘Make A Stand campaign against domestic abuse and delivered on the
              requirements of the pledge. This year we have built on the
              commitments of the pledge. We have:
            </p>
            <ul className="pl-3">
              <li>
                Joined the Middlesbrough Domestic Abuse Strategic Partnership
                and the Tees Wide Domestic Abuse Champions Network to contribute
                to the local, strategic domestic abuse response.
              </li>
              <li>
                Strengthened our relationship with our domestic abuse support
                partner Harbour, providing additional homes to enable more
                support to be provided to survivors of domestic abuse.
              </li>
              <li>
                Appointed and trained six domestic abuse champions, who are our
                organisational ambassadors.
              </li>
              <li>
                Delivered training to all of our front line staff on how to
                recognise and respond to domestic abuse.
              </li>
              <li>
                Supported local and national campaigns and initiatives to end
                domestic abuse including the white ribbon appeal.
              </li>
              <li>Supported 19 families to move into permanent, safe homes.</li>
            </ul>
          </Col>
          <Col xs="12" lg="5">
            <Image
              src={require("assets/images/harbour-image.png")}
              alt="harbour"
              alignCenter={true}
              className="mb-4"
              maxWidth={250}
              minWidth={100}
            />
            <Image
              src={require("assets/images/make-a-stand.png")}
              alt="make a stand"
              alignCenter={true}
              className="mb-4"
              maxWidth={300}
              minWidth={100}
            />
          </Col>
        </Row>
      )
    case 2:
      return (
        <>
          <Row>
            <Col xs="12" lg="7">
              <h3>Tackling Homeslessness</h3>
              <p>
                As a member of Homes for Cathy, a national group of landlords
                working together to end homelessness, we{" "}
                <StyledLink
                  href="https://trello.com/b/5oiGQzUs/homes-for-cathy"
                  target="_blank"
                >
                  published
                </StyledLink>{" "}
                our current work on the nine commitments to tackle homelessness
                and hosted a first north eastern regional workshop. This brought
                together partners from across the north east to discuss how
                we can collaborate on homelessness issues affecting the region.
              </p>
              <p>
                We were awarded funding by Homes England to deliver six homes in
                Stockton as part of the “Move on Fund”. These homes are enabling
                tenants to leave hostel or refuge accommodation, further develop
                their independent living skills and move into a permanent home.
                We have been awarded funding for another six homes in
                Middlesbrough which will be delivered in 2020/21.
              </p>
            </Col>
            <Col xs="12" lg="5">
              <Image
                src={require("assets/images/homelessness-logos.png")}
                alt="homelessness"
                alignCenter={true}
                className="mb-4"
                maxWidth={250}
                minWidth={100}
              />
            </Col>
          </Row>
        </>
      )
    case 3:
      return (
        <>
          <Row>
            <Col xs="12">
              <h3>Maximising income</h3>
              <p>
                Our Welfare Benefit Team provided 927 customers with benefit
                advice and support and maximised customer’s income by £746k. Of
                this amount, £142k accounted for payments of rent, with welfare
                benefit officers making claims for backdated benefit and
                additional housing costs through universal credit (UC),
                strengthening tenancy sustainability for customers.
              </p>
            </Col>
          </Row>
        </>
      )
    case 4:
      return (
        <>
          <Row>
            <Col xs="12" lg="7">
              <h3>Helping to reduce loneliness and improve lives</h3>
              <p>
                Working with customers at our extra care service in Stockton, we
                successfully applied for £10k Big Lottery funding to develop a
                garden project. The project will enable the garden to be
                developed by tenants into a social area to encourage and support
                connection within the scheme and the wider community.
              </p>
              <p>
                Alongside this we received further funding to set up a{" "}
                <StyledLink
                  href="https://equalarts.org.uk/our-work/henpower"
                  target="_blank"
                >
                  “Hen Power”
                </StyledLink>{" "}
                project. Keeping hens has been found to improve wellbeing,
                reduce loneliness and improve depression. Research has found
                hens and other small animals are therapeutic particularly for
                people who may have dementia or a learning disability.
              </p>
            </Col>
            <Col xs="12" lg="5">
              <Image
                src={require("assets/images/loneliness-logos.png")}
                alt="harbour"
                alignCenter={true}
                className="mb-4"
                maxWidth={250}
                minWidth={100}
              />
            </Col>
          </Row>
        </>
      )

    default:
      return null
  }
}

const StyledLink = styled.a`
  color: ${vars.lightBlue};
  text-decoration: underline;
  font-weight: bold;
`
