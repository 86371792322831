import vars from "styles/variables"

export function getColour(colour) {
  switch (colour) {
    case "orange":
      return vars.orange
    case "green":
      return vars.green
    case "dark-blue":
      return vars.darkBlue
    case "light-blue":
      return vars.lightBlue
    case "dark-grey":
      return vars.darkGrey

    default:
      return vars.grey_200
  }
}
