import React from "react"
import { Col, Container, Row } from "reactstrap"
import Slider from "react-slick"
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"
import styled from "styled-components"
import vars from "styles/variables"
import teamData from "data/teamData"

function TeamList() {
  const settings = {
    slidesToShow: 3,
    centerPadding: "60px",
    prevArrow: <BsChevronLeft />,
    nextArrow: <BsChevronRight />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <StyledTeamList>
      <Container>
        <Row>
          <Col>
            <Slider {...settings}>
              {teamData.map(item => (
                <div key={item.id}>
                  <StyledTeamMemberContainer>
                    <img
                      src={item.image}
                      alt={item.name}
                      className="team-member-img"
                    />
                    <StyledTeamMember>
                      <p>
                        <StyledName>{item.name}</StyledName>
                        {item.jobTitle && (
                          <>
                            <br />
                            <strong>{item.jobTitle}</strong>
                          </>
                        )}
                      </p>
                      <p className="mb-0">{item.description}</p>
                    </StyledTeamMember>
                  </StyledTeamMemberContainer>
                </div>
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    </StyledTeamList>
  )
}

export default TeamList

const StyledTeamList = styled.div`
  position: relative;

  div:focus {
    outline: none;
  }

  .team-member-img {
    width: 100%;
    display: block;
    position: relative;
    z-index: 10;
  }

  .slick-arrow {
    position: absolute;
    top: calc(50% - 24px);
    z-index: 30;
    font-size: 3rem;
    cursor: pointer;
  }

  .slick-prev {
    left: -50px;
    @media (max-width: ${vars.screen_xs_max}) {
      left: 0;
    }
  }

  .slick-next {
    right: -50px;
    @media (max-width: ${vars.screen_xs_max}) {
      right: 0;
    }
  }
`

const StyledTeamMemberContainer = styled.div`
  width: 320px;
  margin: 0 auto;
  text-align: center;
`

const StyledName = styled.strong`
  color: ${vars.darkBlue};
`

const StyledTeamMember = styled.div`
  background: ${vars.grey_200};
  padding: 8rem 1.5rem 1rem 1.5rem;
  margin-top: -110px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-bottom: 100px;
  position: relative;

  &:after {
    content: "";
    background: ${vars.grey_200};
    width: calc(100% - 20px);
    height: 100px;
    display: block;
    position: absolute;
    left: 10px;
    border-radius: 20px;
    bottom: -92px;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
  }
`
