import React from "react"
import PageBanner from "components/ui/PageBanner"
import { Col, Container, Row } from "reactstrap"
import { Slide } from "react-reveal"
import Image from "components/common/Image"
import SectionTitle from "components/common/SectionTitle"
import DeliveringImpactContent from "components/ui/DeliveringImpactContent"
import SectionContent from "components/common/SectionContent"
import ExpandableContent from "components/common/ExpandableContent"
import NextPage from "components/common/NextPage"
import styled from "styled-components"
import vars from "styles/variables"

function DeliveringImpact({ bannerImage, slug }) {
  return (
    <>
      <PageBanner src={bannerImage} alt="Delivering Impact" slug={slug} />
      <Container>
        <Row className="align-items-center">
          <Col xs="12" md="6">
            <Slide left>
              <h3 className="mt-4 mt-md-0">
                We care about the people and the communities that we serve. We
                work, in collaboration with others, to create stable, secure,
                trusting and supportive environments where people feel they
                belong We have always worked hard to be “ More than a Landlord”
              </h3>
            </Slide>
          </Col>
          <Col xs="12" md="6" className="text-center">
            <Slide right>
              <Image
                src={require("assets/images/impact-image.png")}
                alt="Impact"
                loadingType="eager"
              />
            </Slide>
          </Col>
        </Row>
        <DeliveringImpactContent />
      </Container>
      <SectionTitle colour="light-blue" title="Feeding young minds" />
      <SectionContent>
        <Row>
          <Col xs="12" md="6">
            <p>
              North Star support four breakfast clubs across the North East in
              partnership with Greggs Foundation. One such club operates in
              Carlin How, East Cleveland. The school says that:
            </p>
            <p>
              The breakfast club include activities, educational games and
              reading activities so that children have an active , as well as a
              nutritious start to the morning, This helps to improve health and
              fitness levels and reduce obesity.
            </p>
            <p>
              Many children in our community do not get the opportunity to have
              breakfast at home and often come to school hungry and lethargic .
              This impacts on children's behaviour and also their ability to
              engage at school. For some children the school lunch is the only
              meal they get. It is essential that children also eat a healthy
              breakfast before they start school so that they are ready for the
              day ahead.
            </p>
          </Col>
          <Col xs="12" md="6">
            <StyledOffsetImage>
              <Image
                src={require("assets/images/feeding-your-mind.png")}
                alt="feeding your mind"
                alignCenter={true}
              />
            </StyledOffsetImage>
          </Col>
        </Row>
      </SectionContent>
      <Container className="mb-5">
        <Row>
          <Col xs="12" md="6" className="mt-5">
            <ExpandableContent
              imgSrc={require("assets/images/bluehex1.png")}
              title="Winning hearts and minds"
              colour="light-blue"
            >
              <p>
                <strong>Winning hearts and minds</strong>
              </p>
              <p>
                Teesdale YMCA provides vital youth engagement services to young
                people in Evenwood and Cockfield. North Star not only provides
                funding for this service but also space at our community house
                on Wellgarth Estate for services to be delivered. Teesdale YMCA
                said of the service
              </p>
              <p>
                “The young people have organised and carried out fundraising
                events to fund trips outside of the local village. This has
                enabled them to enjoy trips to a trampoline park and a swimming
                trip.
              </p>
              <p>
                Once a month young people visit Lyons Court Care home with
                Durham Wildlife Trust and assist the residents in carrying out
                the crafts. The staff of the home have commented on how much the
                residents look forward to their monthly visit and how polite and
                well behaved the young people are.”
              </p>
              <Image
                src={require("assets/images/winning-hearts-bottom-image.png")}
                alt="Winning Hearts"
                alignCenter={true}
              />
            </ExpandableContent>
          </Col>
          <Col xs="12" md="6" className="mt-5">
            <ExpandableContent
              imgSrc={require("assets/images/my-north-star.png")}
              title="MY North Star Improving choice and services"
              colour="light-blue"
            >
              <p>
                <strong>MY North Star - Improving choice and services</strong>
              </p>
              <p>
                In 2018 we set an aim to transform our digital platforms –
                ensuring they are user friendly and user led, accessible, fit
                for purpose and future proofed. In 2019 we began to look at how
                to improve customer experience and choice. We set ourselves a
                target that by 2023 over 60% of our tenants would access
                services online. We knew that around 80% of our tenants accessed
                on line services elsewhere and that it’s a highly efficient way
                to deliver services. My North Star was launched in November 2019
                and close to 50% of all customers, have signed up to use the
                online service; exceeding our expectations and targets. It can
                be accessed from any smart phone, tablet or computer and at any
                time of day or night.
              </p>
              <p>
                A key feature is the ability to report repairs online, at any
                time and from anywhere, and choose an appointment which is
                convenient for the customer. Since launch over 700 repairs and
                appointments have been arranged using the service.
              </p>
            </ExpandableContent>
          </Col>
        </Row>
      </Container>
      <NextPage slug="financial-performance" />
    </>
  )
}

export default DeliveringImpact

const StyledOffsetImage = styled.div`
  margin-top: -40px;
  @media (max-width: ${vars.screen_sm_max}) {
    margin-top: 0;
  }
`
