import React from "react"
import styled, { css } from "styled-components"
import vars from "styles/variables"
import { BsChevronDown } from "react-icons/bs"

function IconHex({ iconPath, text, isActive, ...rest }) {
  return (
    <StyledIconHex isActive={isActive} {...rest}>
      <div>
        {iconPath && (
          <StyledIconContainer>
            <StyledIcon src={iconPath} alt={text} />
          </StyledIconContainer>
        )}
        <span className="d-none d-lg-block">{text}</span>
      </div>
      <StyledChevronDown isActive={isActive} />
    </StyledIconHex>
  )
}

export default IconHex

const StyledIconHex = styled.div`
  background: ${props => (props.isActive ? vars.lightBlue : vars.darkGrey)};
  padding: 0 1.5rem 0.5rem 1.5rem;
  text-align: center;
  color: ${vars.white};
  width: 100%;
  max-width: 255px;
  margin: 80px auto;
  display: block;
  height: 125px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
  transition: ease-in-out 300ms;

  @media (max-width: ${vars.screen_md_max}) {
    margin: 0;
    display: block;
    height: 100%;
    padding: 1rem;
  }

  @media (max-width: ${vars.screen_sm_max}) {
    background: none !important;
    padding: 0;
    color: ${vars.lightBlue};
  }
  @media (min-width: ${vars.screen_sm_min}) {
    &:hover {
      background: ${vars.lightBlue};
      &:before,
      &:after {
        background: ${vars.lightBlue};
      }
    }
  }

  &:before,
  &:after {
    content: "";
    background: ${props => (props.isActive ? vars.lightBlue : vars.darkGrey)};
    width: calc(100% - 20px);
    height: 79px;
    display: block;
    position: absolute;
    left: 10px;
    border-radius: 6px;
    transition: ease-in-out 300ms;
    @media (max-width: ${vars.screen_md_max}) {
      display: none;
    }
  }

  &:before {
    top: -72px;
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
  }

  &:after {
    bottom: -72px;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
  }
`
const StyledIcon = styled.img`
  display: block;
  margin: 0 auto 1rem auto;
  @media (max-width: ${vars.screen_md_max}) {
    margin-bottom: 0;
  }
  @media (max-width: ${vars.screen_sm_max}) {
    width: 75px;
    filter: invert(0.5);
  }
  @media (max-width: ${vars.screen_xs_max}) {
    filter: invert(0.5);
  }
`

const StyledIconContainer = styled.div`
  position: absolute;
  top: -30px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: ${vars.screen_md_max}) {
    position: unset;
  }
`

const ChevronStyle = css`
  position: absolute;
  bottom: -48px;
  left: calc(50% - 21px);
  font-size: 2.6rem;
  z-index: 30;
  transition: ease-in-out 300ms;
  transform: rotate(${props => (props.isActive ? "180deg" : 0)});
  @media (max-width: ${vars.screen_md_max}) {
    position: unset;
    display: block;
    margin: 0 auto;
  }
  @media (max-width: ${vars.screen_sm_max}) {
    font-size: 2rem;
  }
`

const StyledChevronDown = styled(({ isActive, ...rest }) => (
  <BsChevronDown {...rest} />
))`
  ${ChevronStyle}
`
