import React from "react"
import styled from "styled-components"

function Image({
  src,
  alt,
  loadingType,
  alignCenter,
  minWidth,
  maxWidth,
  ...rest
}) {
  return (
    <StyledImage
      src={src}
      alt={alt}
      {...rest}
      loading={loadingType || "lazy"}
      alignCenter={alignCenter}
      minWidth={minWidth}
      maxWidth={maxWidth}
    />
  )
}

export default Image

const StyledImage = styled.img`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : "100%")};
  min-width: ${props => (props.minWidth ? `${props.minWidth}px` : "100%")};
  margin-left: ${props => (props.alignCenter ? "auto" : 0)};
  margin-right: ${props => (props.alignCenter ? "auto" : 0)};
  display: block;
`
