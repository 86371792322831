import React from "react"
import styled from "styled-components"
import { Row, Col, Container } from "reactstrap"
import vars from "styles/variables"
import mainNavData from "data/mainNavData"
import { Link } from "react-router-dom"
import { motion } from "framer-motion"

const mobileNavVariant = {
  initial: {
    opacity: 0,
    x: "100%",
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    x: "100%",
    transition: {
      duration: 0.3,
      when: "afterChildren",
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
}

function MobileMenu({ handleCloseNav }) {
  return (
    <StyledMobileMenu
      variants={mobileNavVariant}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <Container>
        <Row>
          {mainNavData.map((item, index) => (
            <Col xs="12" lg="6" key={index}>
              <motion.div variants={mobileNavVariant}>
                <Link
                  to={`/${item.slug}`}
                  className={item.slug}
                  onClick={handleCloseNav}
                >
                  {item.title}
                </Link>
              </motion.div>
            </Col>
          ))}
        </Row>
      </Container>
    </StyledMobileMenu>
  )
}

export default MobileMenu

const StyledMobileMenu = styled(motion.div)`
  width: 100%;
  height: 100vh;
  background: ${vars.blackTransparent(0.6)};
  position: fixed;
  top: 71px;
  left: 0;
  z-index: 600;
  padding-top: 1rem;

  @media (min-width: ${vars.screen_lg_min}) {
    height: auto;
  }

  a {
    color: ${vars.white};
    font-size: 1.5rem;
    margin-bottom: 2rem;
    display: block;
    border-bottom-width: 2px;
    border-bottom-style: solid;

    &.foreword {
      border-color: ${vars.green};
    }
    &.creating-homes-building-futures {
      border-color: ${vars.orange};
    }
    &.delivering-impact {
      border-color: ${vars.lightBlue};
    }
    &.financial-performance {
      border-color: ${vars.darkBlue};
    }
  }
`
