import React from "react"
import styled from "styled-components"
import vars from "styles/variables"
import { Fade } from "react-reveal"
import { Col, Container, Row } from "reactstrap"

function SectionContent({ children, isPointVisible = true }) {
  return (
    <StyledSectionContent>
      <Container>
        <Row>
          <Col>
            <Fade bottom>{children}</Fade>
          </Col>
        </Row>
      </Container>
      {isPointVisible && (
        <StyledContentPoint
          src={require("assets/images/grey-point.png")}
          alt="Content Point"
        />
      )}
    </StyledSectionContent>
  )
}

export default SectionContent

const StyledSectionContent = styled.div`
  background: ${vars.grey_200};
  padding: 2.5rem 0 1.2rem 0;
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;
`

const StyledContentPoint = styled.img`
  position: absolute;
  left: calc(50% - 48px);
  bottom: -45px;
  z-index: 10;
`
