import React from "react"
import { Col, Container, Row } from "reactstrap"
import styled from "styled-components"
import vars from "styles/variables"
import { Fade } from "react-reveal"
import { getColour } from "utils/styleUtil"

function SectionTitle({ title, colour }) {
  return (
    <StyledSectionTitle colour={colour} className="mt-5">
      <Container>
        <Row>
          <Col xs="12" xl="8">
            <Fade bottom>
              <h2>{title}</h2>
            </Fade>
          </Col>
        </Row>
      </Container>
      <StyledTitlePoint src={getPointAsset(colour)} alt="Title Point" />
    </StyledSectionTitle>
  )
}

export default SectionTitle

function getPointAsset(colour) {
  switch (colour) {
    case "orange":
      return require("assets/images/orange-point.png")
    case "light-blue":
      return require("assets/images/light-blue-point.png")
    case "dark-blue":
      return require("assets/images/dark-blue-point.png")
    default:
      return require("assets/images/grey-point.png")
  }
}

const StyledSectionTitle = styled.div`
  padding: 1rem 0;
  color: ${props => (props.colour ? vars.white : vars.darkBlue)};
  background: ${props => getColour(props.colour)};
  position: relative;
  overflow: hidden;

  h2 {
    margin: 0;
  }
`

const StyledTitlePoint = styled.img`
  position: absolute;
  left: calc(50% - 48px);
  bottom: -45px;
  z-index: 10;
`
