import React from "react"
import PageBanner from "components/ui/PageBanner"
import { Col, Container, Row } from "reactstrap"
import { Zoom, Slide } from "react-reveal"
import styled from "styled-components"
import Image from "components/common/Image"
import SectionTitle from "components/common/SectionTitle"
import SectionContent from "components/common/SectionContent"
import vars from "styles/variables"
import PieChart from "components/ui/PieChart"
import TeamList from "components/ui/TeamList"
import NextPage from "components/common/NextPage"

function FinancialPerformance({ bannerImage, slug }) {
  return (
    <>
      <PageBanner src={bannerImage} alt="Financial Performance" slug={slug} />
      <Container className="mb-5">
        <Row className="align-items-center">
          <Col xs="12" lg="6">
            <Slide left>
              <p className="mt-4 mt-lg-0">
                <strong>
                  Being a financially strong and well governed organisation is
                  very important to us.
                </strong>
              </p>
              <h3>Strong Governance</h3>
              <p>
                The Board operates with a clear purpose using agreed ways of
                operating that strengthen decision making. The Board continues
                to pre-empt and respond to a constantly complex and changing
                world through excellent planning, responsiveness, flexibility
                and clear direction. Board skills are fully aligned to the
                ambitions of the business and constantly reviewed. The Board
                comprises of:
              </p>
            </Slide>
          </Col>
          <Col xs="12" lg="6" className="text-center">
            <Slide right>
              <Image
                src={require("assets/images/governance-image.png")}
                alt="Governance"
                loadingType="eager"
                alignCenter={true}
              />
            </Slide>
          </Col>
        </Row>
      </Container>
      <Slide bottom>
        <TeamList />
      </Slide>
      <SectionTitle colour="dark-blue" title="Financial strength" />
      <SectionContent isPointVisible={false}>
        <Row className="align-items-center mt-5">
          <Col xs="12" lg="6" className="mb-4">
            <p>
              <strong>
                As a not for profit housing group, all surpluses are invested
                back into the organisation. Surplus is used to deliver the
                organisational purpose and to that end we invest in:
              </strong>
            </p>
          </Col>
          <Col xs="12" lg="6" className="mb-4">
            <Row className="align-items-center">
              <Col xs="auto">
                <Image
                  src={require("assets/images/group-13.png")}
                  alt="New housing"
                />
              </Col>
              <Col>
                <h3 className="mb-2">New housing</h3>
                <p className="mb-0">
                  to provide local communities with high quality and fuel
                  efficient, affordable housing to meet a range of needs.
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs="12" lg="6" className="mb-4">
            <Row className="align-items-center">
              <Col xs="auto">
                <Image
                  src={require("assets/images/group-11.png")}
                  alt="Existing housing"
                />
              </Col>
              <Col>
                <h3 className="mb-2">Existing housing</h3>
                <p className="mb-0">
                  to ensure tenants live in safe, warm and well maintained
                  houses.
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs="12" lg="6" className="mb-4">
            <Row className="align-items-center">
              <Col xs="auto">
                <Image
                  src={require("assets/images/group-14.png")}
                  alt="Communities and neighbourhoods"
                />
              </Col>
              <Col>
                <h3 className="mb-2">Communities and neighbourhoods</h3>
                <p className="mb-0">
                  to facilitate and enable people to thrive.
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs="12" lg="6" className="mb-4">
            <Row className="align-items-center">
              <Col xs="auto">
                <Image
                  src={require("assets/images/group-12.png")}
                  alt="People"
                />
              </Col>
              <Col>
                <h3 className="mb-2">People</h3>
                <p className="mb-0">
                  to enable everyone to have a voice, influence and involvement
                  at all levels.
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs="12" lg="6" className="mb-4">
            <Row className="align-items-center">
              <Col xs="auto">
                <Image
                  src={require("assets/images/group-15.png")}
                  alt="Partnerships"
                />
              </Col>
              <Col>
                <h3 className="mb-2">Partnerships</h3>
                <p className="mb-0">
                  so that we can work together to find solutions to challenging
                  housing issues.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </SectionContent>
      <Container>
        <Row className="mt-5">
          <Col xs="12">
            <Zoom>
              <StyledResponsiveTable>
                <Image
                  src={require("assets/images/key-table.png")}
                  alt="Key Performance"
                  alignCenter={true}
                  minWidth={800}
                />
              </StyledResponsiveTable>
            </Zoom>
          </Col>
          <Col xs="12" lg="6" className="mt-4">
            <p className="mb-0">
              <strong>
                We aim for all performance to be upper quartile and within our
                internal targets. Improvement Plans are developed for any area
                outside of these targets
              </strong>
            </p>
          </Col>
          <Col xs="12" lg="6" className="mt-4">
            <p className="mb-0">
              <strong>
                *Data sourced from Housemark Benchmarking
                <br />
                **Benchmarking data is not available
              </strong>
            </p>
          </Col>
        </Row>
      </Container>
      <SectionTitle title="Homes" />
      <div style={{ backgroundColor: vars.darkBlue }}>
        <Slide right>
          <Image
            src={require("assets/images/bar-chart-blue.png")}
            alt="Finance Bar Chart"
            alignCenter={true}
          />
        </Slide>
      </div>
      <Container className="mb-5">
        <Row>
          <Col xs="12" className="mt-5">
            <PieChart />
          </Col>
          <Col xs="12" className="mt-5">
            <Zoom>
              <StyledResponsiveTable>
                <Image
                  src={require("assets/images/table2.png")}
                  alt="Table 2"
                  alignCenter={true}
                  minWidth={800}
                />
              </StyledResponsiveTable>
            </Zoom>
          </Col>
        </Row>
      </Container>
      <NextPage slug="foreword" />
    </>
  )
}

export default FinancialPerformance

const StyledResponsiveTable = styled.div`
  overflow-x: scroll;
  display: block;
`
