import React, { useState } from "react"
import styled from "styled-components"
import { BsChevronDown } from "react-icons/bs"
import { MdClose } from "react-icons/md"
import { Fade } from "react-reveal"
import vars from "styles/variables"
import Image from "components/common/Image"
import { getColour } from "utils/styleUtil"

function ExpandableContent({ imgSrc, title, children, colour }) {
  const [showContent, setShowContent] = useState(false)

  return (
    <Fade bottom>
      <StyledExpandableContent>
        <div className="position-relative">
          <Image src={imgSrc} alt={title} alignCenter={true} />
          <StyledTitle onClick={() => setShowContent(() => !showContent)}>
            <div>
              <Fade bottom>
                <h2>{title}</h2>
                <span>
                  SHOW MORE <BsChevronDown />
                </span>
              </Fade>
            </div>
          </StyledTitle>
        </div>
        {showContent && (
          <StyledContent>
            {children}
            <StyledClose colour={colour} onClick={() => setShowContent(false)}>
              <span>Close</span>
              <MdClose />
            </StyledClose>
          </StyledContent>
        )}
      </StyledExpandableContent>
    </Fade>
  )
}

export default ExpandableContent

const StyledExpandableContent = styled.div`
  position: relative;
`

const StyledTitle = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  color: ${vars.white};
  cursor: pointer;
  padding: 0 3rem;
`

const StyledContent = styled.div`
  background: ${vars.grey_200};
  padding: 13rem 2rem 2rem 2rem;
  margin-top: -185px;
  margin-bottom: 150px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  @media (max-width: ${vars.screen_xs_max}) {
    margin-bottom: 110px;
  }

  &:after {
    content: "";
    background: ${vars.grey_200};
    width: calc(100% - 20px);
    height: 150px;
    display: block;
    position: absolute;
    bottom: -143px;
    left: 10px;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    @media (max-width: ${vars.screen_xs_max}) {
      height: 110px;
      bottom: -103px;
    }
  }
`
const StyledClose = styled.div`
  text-align: center;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -45px;
  left: calc(50% - 39px);
  z-index: 10;
  cursor: pointer;
  transition: ease-in-out 300ms;

  &:hover {
    color: ${props => getColour(props.colour)};
  }

  svg {
    font-size: 1.4rem;
  }
`
