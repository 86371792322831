import React from "react"
import { Row, Col, Container } from "reactstrap"
import { motion } from "framer-motion"
import styled from "styled-components"
import vars from "styles/variables"
import mainNavData from "data/mainNavData"

function MainNav({ handleNavClick, variants, ...rest }) {
  return (
    <StyledNav
      variants={variants}
      initial="initial"
      animate="animate"
      {...rest}
    >
      <Container fluid className="p-0">
        <Row noGutters>
          {mainNavData.map((item, index) => (
            <Col xs="6" md="6" lg="3" key={index}>
              <StyledNavLink
                href="/"
                onClick={e => handleNavClick(e, index)}
                className={`nav-item-${index + 1}`}
              >
                {item.title}
              </StyledNavLink>
            </Col>
          ))}
        </Row>
      </Container>
    </StyledNav>
  )
}

export default MainNav

const StyledNav = styled(motion.nav)`
  background: ${vars.white};
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 500;
`
const StyledNavLink = styled.a`
  padding: 2rem 0;
  display: block;
  background: ${vars.white};
  transition: ease-in-out 200ms;
  font-weight: 600;
  color: ${vars.grey};

  &:hover {
    color: ${vars.white};

    &.nav-item-1 {
      background: ${vars.green};
    }
    &.nav-item-2 {
      background: ${vars.orange};
    }
    &.nav-item-3 {
      background: ${vars.lightBlue};
    }
    &.nav-item-4 {
      background: ${vars.darkBlue};
    }
  }
`
