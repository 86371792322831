import React from "react"
import PageBanner from "components/ui/PageBanner"
import { Col, Container, Row } from "reactstrap"
import { Zoom, Slide } from "react-reveal"
import Image from "components/common/Image"
import NextPage from "components/common/NextPage"

function Foreword({ bannerImage, slug }) {
  return (
    <>
      <PageBanner src={bannerImage} alt="Foreword" slug={slug} />
      <Container className="py-4">
        <Row className="align-items-center">
          <Col xs="12" md="6" className="mb-4">
            <Zoom>
              <Image
                src={require("assets/images/peter-waugh.png")}
                alt="Peter Waugh"
                loadingType="eager"
              />
            </Zoom>
          </Col>
          <Col xs="12" md="6" className="mb-2 mb-md-4">
            <Slide right>
              <h2>Hello and welcome to our annual report for 2019/20</h2>
            </Slide>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <Slide bottom>
              <p>
                You do not need us to say how challenging the year has been for
                everyone so far, and our services have understandably suffered
                some disruption. We have managed to keep this to an absolute
                minimum and throughout the COVID-19 crisis we have been very
                present, connected and engaged with customers and partners to
                provide support and to deliver services. We have been very
                mindful to ensure that the health and safety of everyone is
                managed effectively.
              </p>
              <p className="mb-4">
                We have introduced and developed further our technology,
                enabling more efficient processes, such as virtual tours of
                properties, online engagement with tenants and a webchat
                facility to name but a few. We have also been working very hard
                to connect with tenants at a personal level where needed through
                telephone calls, providing shopping and prescription collection
                and advising on welfare benefits.
              </p>
            </Slide>
            <Zoom>
              <Image
                src={require("assets/images/angela-lockwood.png")}
                alt="Angela Lockwood"
              />
            </Zoom>
          </Col>
          <Col xs="12" md="6">
            <Slide bottom>
              <p>
                We have engaged with communities and charities and have made
                financial contributions to food banks and food services and many
                other local initiatives.
              </p>
              <p>
                This all fits with North Stars values that have been our
                touchpoint throughout. The energy and investment in
                organisational and people development to create high levels of
                trust have reaped their rewards through these challenging times.
                Our staff have been superb and have gone many extra miles;
                caring, committed, dependable, creative and driven.
              </p>
              <p>
                Our very focussed and dedicated Board has provided strategic
                direction, flexibility and pragmatism. We have all worked hard
                to keep North Star strong.
              </p>
              <p>
                Our exciting work has continued and you will read of the new
                housing we have built, the initiatives we are involved in, the
                services we are providing and of our technological advancements.
                We welcome and value your feedback on every aspect of our work;
                it's how we continue to improve.
              </p>
              <p>We wish you future good health.</p>
              <p>
                <strong>Peter Waugh</strong>
                <br />
                Chair of North Star Board
              </p>
              <p>
                <strong>Angela Lockwood</strong>
                <br />
                CEO of North Star Housing Group
              </p>
            </Slide>
          </Col>
        </Row>
      </Container>
      <NextPage slug="creating-homes-building-futures" />
    </>
  )
}

export default Foreword
