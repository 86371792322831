import React from "react"
import Button from "components/common/Button"
import ButtonTypeEnum from "enums/buttonTypeEnum"

function HeroBannerContent({ imageIndex }) {
  switch (imageIndex) {
    case 1:
      return (
        <>
          <h2>Foreword</h2>
          <Button to="/foreword" styleType={ButtonTypeEnum.white}>
            View More
          </Button>
        </>
      )
    case 2:
      return (
        <>
          <h2>
            Creating Homes,
            <br />
            Building Futures
          </h2>
          <Button
            to="/creating-homes-building-futures"
            styleType={ButtonTypeEnum.white}
          >
            View More
          </Button>
        </>
      )
    case 3:
      return (
        <>
          <h2>
            Delivering
            <br />
            impact
          </h2>
          <Button to="/delivering-impact" styleType={ButtonTypeEnum.white}>
            View More
          </Button>
        </>
      )
    case 4:
      return (
        <>
          <h2>
            Strong governance
            <br />
            and financial
            <br />
            performance
          </h2>
          <Button to="/financial-performance" styleType={ButtonTypeEnum.white}>
            View More
          </Button>
        </>
      )
    default:
      return (
        <>
          <h3 className="mb-0 text-uppercase">Annual Report 2020</h3>
          <h1 className="mb-0">NORTH STAR</h1>
          <h3>
            <span>
              Guiding you home,
              <br />
              to a <strong>brighter</strong> future
            </span>
          </h3>
        </>
      )
  }
}

export default HeroBannerContent
