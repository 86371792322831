import React from "react"
import { Link } from "react-router-dom"
import pageData from "data/pageData"
import Fade from "react-reveal/Fade"
import { Col, Container, Row } from "reactstrap"
import styled from "styled-components"
import vars from "styles/variables"
import { getColour } from "utils/styleUtil"
import { lighten } from "polished"
import { BsChevronRight } from "react-icons/bs"

function NextPage({ slug }) {
  const data = pageData.find(x => x.slug === slug)

  return (
    <StyledNextPageContainer>
      <Fade bottom>
        <Link to={`/${data.slug}`} onClick={() => window.scrollTo(0, 0)}>
          <StyledNextPage colour={getColour(data.colour)}>
            <Container>
              <Row>
                <Col xs="12" xl="6">
                  <StyledNextPageContent>
                    <h3>
                      <strong className="mr-2">Next |</strong>
                      <span className="mr-2">{data.title}</span>
                      <BsChevronRight
                        style={{ position: "relative", top: 4 }}
                      />
                    </h3>
                    <p className="mb-0">{data.description}</p>
                  </StyledNextPageContent>
                </Col>
              </Row>
            </Container>
            <StyledNextPageImage
              src={data.image}
              alt={data.title}
              loading="lazy"
              className="d-none d-xl-block"
            />
          </StyledNextPage>
        </Link>
      </Fade>
    </StyledNextPageContainer>
  )
}

export default NextPage

const StyledNextPageContainer = styled.div`
  padding-top: 200px;
  @media (max-width: ${vars.screen_lg_max}) {
    padding-top: 0;
  }
`
const StyledNextPage = styled.div`
  background: ${props => props.colour};
  background: ${props =>
    `linear-gradient(90deg, ${props.colour} 0%, ${lighten(
      0.2,
      props.colour
    )} 100%)`};
  color: ${vars.white};
  position: relative;

  h3 {
    margin-bottom: 0.5rem;
    font-weight: 400;
  }
`

const StyledNextPageImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
`

const StyledNextPageContent = styled.div`
  padding: 1.5rem 0;
`
