import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import Home from "pages/Home"
import Layout from "components/ui/Layout"
import NotFound from "pages/NotFound"
import { Helmet } from "react-helmet"
import Foreword from "pages/Foreword"
import homeBanner from "assets/images/home-banner.jpg"
import forewordBanner from "assets/images/foreword-banner.jpg"
import creatingHomesBanner from "assets/images/creating-homes-banner.jpg"
import deliveringBanner from "assets/images/delivering-banner.jpg"
import strongBanner from "assets/images/strong-banner.jpg"
import CreatingHomes from "./pages/CreatingHomes"
import DeliveringImpact from "./pages/DeliveringImpact"
import FinancialPerformance from "./pages/FinancialPerformance"

const bannerImages = [
  homeBanner,
  forewordBanner,
  creatingHomesBanner,
  deliveringBanner,
  strongBanner,
]

function App() {
  return (
    <Layout>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Switch>
        <Route
          exact
          path="/financial-performance"
          component={() => (
            <FinancialPerformance
              bannerImage={bannerImages[4]}
              slug="financial-performance"
            />
          )}
        />
        <Route
          exact
          path="/delivering-impact"
          component={() => (
            <DeliveringImpact
              bannerImage={bannerImages[3]}
              slug="delivering-impact"
            />
          )}
        />
        <Route
          exact
          path="/creating-homes-building-futures"
          component={() => (
            <CreatingHomes
              bannerImage={bannerImages[2]}
              slug="creating-homes-building-futures"
            />
          )}
        />
        <Route
          exact
          path="/foreword"
          component={() => (
            <Foreword bannerImage={bannerImages[1]} slug="foreword" />
          )}
        />
        <Route
          exact
          path="/"
          component={() => <Home bannerImages={bannerImages} />}
        />
        <Route to="/not-found" component={NotFound} exact />
        <Redirect to="/not-found" />
      </Switch>
      {/*//! Simple way to pre-load images to try and prevent any animation issues when the images first load */}
      <div style={{ display: "none" }}>
        {bannerImages.map(i => (
          <img key={i} src={i} alt="Pre-Load Banner" loading="eager" />
        ))}
      </div>
    </Layout>
  )
}

export default App
