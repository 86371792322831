import React from "react"
import Image from "components/common/Image"
import { Slide, Rotate } from "react-reveal"
import { Row, Col } from "reactstrap"
import styled from "styled-components"
import vars from "styles/variables"

function PieChart() {
  return (
    <StyledPieChart>
      <Rotate bottom right>
        <Image
          src={require("assets/images/pie-chart.png")}
          alt="Pie Chart"
          alignCenter={true}
          maxWidth={700}
          minWidth={300}
          className="w-100"
        />
      </Rotate>
      <StyledPieChartKey className="mt-4 mt-lg-0">
        <Slide right>
          <Row>
            <Col xs="6" sm="4" lg="12" className="mb-2">
              <div className="d-flex align-items-center">
                <StyledKeyBullet colour="#004a8a" /> Maintenance
              </div>
            </Col>
            <Col xs="6" sm="4" lg="12" className="mb-2">
              <div className="d-flex align-items-center">
                <StyledKeyBullet colour="#336ea1" /> Staffing
              </div>
            </Col>
            <Col xs="6" sm="4" lg="12" className="mb-2">
              <div className="d-flex align-items-center">
                <StyledKeyBullet colour="#7fa4c4" /> Interest
              </div>
            </Col>
            <Col xs="6" sm="4" lg="12" className="mb-2">
              <div className="d-flex align-items-center">
                <StyledKeyBullet colour="#00b0ea" /> Depreciation
              </div>
            </Col>
            <Col xs="6" sm="4" lg="12" className="mb-2">
              <div className="d-flex align-items-center">
                <StyledKeyBullet colour="#7fd7f4" /> Services
              </div>
            </Col>
            <Col xs="6" sm="4" lg="12">
              <div className="d-flex align-items-center">
                <StyledKeyBullet colour="#cceffb" /> Other
              </div>
            </Col>
          </Row>
        </Slide>
      </StyledPieChartKey>
    </StyledPieChart>
  )
}

export default PieChart

const StyledPieChart = styled.div`
  position: relative;
`
const StyledPieChartKey = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 160px;

  @media (max-width: ${vars.screen_md_max}) {
    position: relative;
    width: 100%;
  }
`
const StyledKeyBullet = styled.div`
  background: ${props => props.colour || vars.grey_200};
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  display: inline-block;
`
