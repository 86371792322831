import React from "react"
import { Col, Row } from "reactstrap"
import styled from "styled-components"
import vars from "styles/variables"

function Quote({ text }) {
  return (
    <Row className="align-items-center">
      <Col xs="auto" className="d-none d-sm-block">
        <img
          src={require("assets/icons/quote-white-left.svg")}
          alt=""
          className="quote-left"
        />
      </Col>
      <Col>
        <StyledQuote>{text}</StyledQuote>
      </Col>
      <Col xs="auto" className="d-none d-sm-block">
        <img
          src={require("assets/icons/quote-white-right.svg")}
          alt=""
          className="quote-right"
        />
      </Col>
    </Row>
  )
}

export default Quote

const StyledQuote = styled.div`
  font-size: 1.3rem;
  color: ${vars.white};
`
