import React from "react"
import styled from "styled-components"
import GlobalStyle from "styles/globalStyle"
import Header from "components/ui/Header"

function Layout({ children }) {
  return (
    <LayoutContainer>
      <GlobalStyle />
      <Header />
      <main className="overflow-hidden">{children}</main>
    </LayoutContainer>
  )
}

export default Layout

const LayoutContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`
