// Colours
const black = "#000000"
const white = "#ffffff"
const pink = "#d73195"
const purple = "#942FE4"
const green = "#afca0b"
const darkBlue = "#004a8a"
const lightBlue = "#00b0ea"
const orange = "#f5a623"
const grey = "#737373"
const darkGrey = "#6d6d6d"

const primary = purple
const secondary = pink
const textColour = "#6b6b6b"

// Gradients
const primaryGradient = "linear-gradient(to bottom, #b861ff 0%, #942FE4 100%)"

// TODO: Review the below hex values for shades of grey and make sure we are happy with them.
const grey_100 = "#f2f2f2"
const grey_200 = "#f5f5f5"
const grey_300 = "#a5a5a5"
const grey_400 = "#737371"
const grey_500 = "#666666"
const grey_600 = "#5a5a5a"
const grey_700 = "#4d4d4d"
const grey_800 = "#141414"

// Breakpoints
const screen_xs_max = "575px"
const screen_sm_min = "576px"
const screen_sm_max = "767px"
const screen_md_min = "768px"
const screen_md_max = "991px"
const screen_lg_min = "992px"
const screen_lg_max = "1199px"
const screen_xl_min = "1200px"

// Fonts
const primaryFont = `'Montserrat', sans-serif`

// Functions
const blackTransparent = (transparency = "0.5") =>
  `rgba(0, 0, 0, ${transparency})`

export default {
  black,
  white,
  pink,
  purple,
  green,
  lightBlue,
  darkBlue,
  orange,
  grey,
  darkGrey,

  primary,
  secondary,
  textColour,

  primaryGradient,

  grey_100,
  grey_200,
  grey_300,
  grey_400,
  grey_500,
  grey_600,
  grey_700,
  grey_800,

  screen_xs_max,
  screen_sm_min,
  screen_sm_max,
  screen_md_min,
  screen_md_max,
  screen_lg_min,
  screen_lg_max,
  screen_xl_min,

  primaryFont,

  blackTransparent,
}
