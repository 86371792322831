import React from "react"
import styled from "styled-components"
import vars from "styles/variables"
import { Fade } from "react-reveal"
import { Col, Container, Row } from "reactstrap"
import { useHistory } from "react-router-dom"
import { MdChevronLeft } from "react-icons/md"
import PageBannerContent from "./PageBannerContent"

function PageBanner({ src, alt, slug }) {
  const history = useHistory()

  const handleGoBack = e => {
    e.preventDefault()
    history.goBack()
  }

  return (
    <>
      <StyledPageBanner>
        <StyledPageBannerImage src={src} alt={alt} />
        <StyledPageBannerContent>
          <Container>
            <Row>
              <Col>
                <Fade bottom>
                  <StyledBackLink href="/" onClick={handleGoBack}>
                    <MdChevronLeft />
                    Back
                  </StyledBackLink>
                  <PageBannerContent slug={slug} />
                </Fade>
              </Col>
            </Row>
          </Container>
          <StyledScrollText>
            <div className="pb-3">
              <Fade top cascade>
                Scroll to view
              </Fade>
            </div>
            <span className="scroll-line"></span>
          </StyledScrollText>
        </StyledPageBannerContent>
      </StyledPageBanner>
    </>
  )
}

export default PageBanner

const StyledPageBanner = styled.div`
  width: 100%;
  height: 100vh;
  outline: none;
  border: none;
  display: block;
  overflow: hidden;

  @media (max-width: ${vars.screen_xs_max}) {
    min-height: 500px;
  }

  h1,
  h2 {
    text-transform: uppercase;
    font-size: 2.5rem;

    @media (max-width: ${vars.screen_xs_max}) {
      font-size: 2rem;
    }
  }

  h3 {
    text-transform: uppercase;
    font-weight: 300;
  }
`
const StyledPageBannerImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  @media (max-width: 1500px) {
    object-fit: cover;
  }
`

const StyledPageBannerContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  color: ${vars.white};
  height: 100%;

  @media (max-width: ${vars.screen_xs_max}) {
    top: 0;
  }
`

const StyledBackLink = styled.a`
  color: ${vars.white};
  display: inline-flex;
  align-items: center;
  svg {
    font-size: 1.5rem;
    margin-top: -1px;
  }
  margin-bottom: 1rem;
`

const StyledScrollText = styled.div`
  color: ${vars.white};
  position: absolute;
  bottom: 0;
  left: calc(50% - 63px);
  font-size: 1.3rem;
  text-shadow: 0 0 5px #808080;

  .scroll-line {
    width: 3px;
    background: ${vars.white};
    height: 50px;
    clear: both;
    display: block;
    position: relative;
    left: calc(50% - 1.5px);
  }
`
