import React from "react"
import pageBannerData from "data/pageBannerData"
import styled from "styled-components"

function PageBannerContent({ slug }) {
  const pageContent = pageBannerData.filter(x => x.slug === slug)[0]

  switch (slug) {
    case "foreword":
      return (
        <>
          <h1>Foreword</h1>
        </>
      )
    case "creating-homes-building-futures":
      return (
        <>
          <h1>
            Creating Homes,
            <br />
            Building Futures
          </h1>
          <div className="d-none d-md-block">
            {pageContent?.content.map((item, index) => (
              <StyledBullet key={index}>
                <img
                  src={require("assets/icons/hex-bullet.svg")}
                  alt="Bullet"
                />{" "}
                {item}
              </StyledBullet>
            ))}
          </div>
        </>
      )
    case "delivering-impact":
      return (
        <>
          <h1>
            Delivering
            <br />
            impact
          </h1>
          <div className="d-none d-md-block">
            {pageContent?.content.map((item, index) => (
              <StyledBullet
                key={index}
                className={
                  pageContent.content.length === index + 1 ? "mb-0" : undefined
                }
              >
                <img
                  src={require("assets/icons/hex-bullet.svg")}
                  alt="Bullet"
                />{" "}
                {item}
              </StyledBullet>
            ))}
          </div>
        </>
      )
    case "financial-performance":
      return (
        <>
          <h1>
            Strong governance
            <br />
            and financial
            <br />
            performance
          </h1>
          <div className="d-none d-md-block">
            {pageContent?.content.map((item, index) => (
              <StyledBullet key={index}>
                <img
                  src={require("assets/icons/hex-bullet.svg")}
                  alt="Bullet"
                />
                {item}
              </StyledBullet>
            ))}
          </div>
        </>
      )
    default:
      return null
  }
}

export default PageBannerContent

const StyledBullet = styled.p`
  display: flex;
  align-items: center;

  img {
    margin-right: 1rem;
  }
`
