import React, { useState } from "react"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import MainNav from "components/ui/MainNav"
import vars from "styles/variables"
import useLockBodyScroll from "hooks/useLockBodyScroll"
import HeroBannerContent from "components/ui/HeroBannerContent"
import { Container, Row, Col } from "reactstrap"

const initialScale = 0.7

const heroBannerSlider = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: [0, 1, 1],
    scale: [initialScale, initialScale, 1],
    x: [-500, 0, 0],
    transition: {
      duration: 1,
      when: "beforeChildren",
      times: [0, 0.5, 1],
    },
  },
  exit: {
    opacity: [1, 1, 0],
    scale: [1, initialScale, initialScale],
    x: [0, 0, 500],
    transition: {
      duration: 1,
      when: "afterChildren",
      times: [0, 0.5, 1],
    },
  },
}

const heroBannerContent = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
}

function Home(props) {
  useLockBodyScroll()
  const [imageIndex, setImageIndex] = useState(0)
  const { bannerImages } = props

  const handleNavClick = (e, index) => {
    e.preventDefault()
    setImageIndex(index + 1)
  }
  //! We need a way of only animating the image in once it has loaded,
  //! we could look at pre-loading the images if that is a better solution
  return (
    <>
      <MainNav handleNavClick={handleNavClick} className="d-none d-lg-block" />
      <AnimatePresence exitBeforeEnter>
        <MotionHeroBanner
          key={bannerImages[imageIndex]}
          variants={heroBannerSlider}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <StyledHomeBanner src={bannerImages[imageIndex]} />
          <StyledHomeContent>
            <MotionBannerText variants={heroBannerContent}>
              <Container>
                <Row>
                  <Col>
                    <HeroBannerContent imageIndex={imageIndex} />
                  </Col>
                </Row>
              </Container>
            </MotionBannerText>
          </StyledHomeContent>
        </MotionHeroBanner>
      </AnimatePresence>
    </>
  )
}

export default Home

const MotionHeroBanner = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  outline: none;
  border: none;
  display: block;

  h1,
  h2 {
    text-transform: uppercase;
    font-size: 2.5rem;
  }

  h3 {
    font-weight: 300;
    span {
      font-size: 1.8rem;
    }
    strong {
      font-weight: 600;
    }
  }
`
const StyledHomeBanner = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;

  @media (max-width: 1500px) {
    object-fit: cover;
  }
`

const StyledHomeContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  color: ${vars.white};
  height: 100%;
`

const MotionBannerText = styled(motion.div)`
  width: 100%;
`
