import React, { useState } from "react"
import { Row, Col, Container } from "reactstrap"
import styled from "styled-components"
import { AnimatePresence } from "framer-motion"
import { Link } from "react-router-dom"
import { Zoom } from "react-reveal"
import vars from "styles/variables"
import { MdMenu, MdClose } from "react-icons/md"
import MobileMenu from "components/ui//MobileMenu"

function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false)

  return (
    <>
      <StyledHeader>
        <Container>
          <Row className="align-items-center">
            <Col>
              <Link to="/" onClick={() => setIsNavOpen(false)}>
                <StyledLogo src={require("assets/logos/ns-logo.svg")} />
              </Link>
            </Col>
            <Col className="d-none d-lg-block text-center">
              Annual Report 2020
            </Col>
            <Col className="text-right">
              <StyledMenuIcon onClick={() => setIsNavOpen(() => !isNavOpen)}>
                {!isNavOpen && (
                  <Zoom>
                    <MdMenu />
                  </Zoom>
                )}
                {isNavOpen && (
                  <Zoom>
                    <MdClose />
                  </Zoom>
                )}
              </StyledMenuIcon>
            </Col>
          </Row>
        </Container>
      </StyledHeader>
      <AnimatePresence>
        {isNavOpen && (
          <MobileMenu
            handleCloseNav={() => {
              window.scrollTo(0, 0)
              setIsNavOpen(false)
            }}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default Header

const StyledHeader = styled.header`
  padding: 1rem 0;
  background: ${vars.blackTransparent(0.6)};
  color: ${vars.white};
  position: fixed;
  top: left;
  width: 100%;
  z-index: 700;
  overflow: hidden;
`

const StyledLogo = styled.img`
  max-width: 160px;
`
const StyledMenuIcon = styled.div`
  font-size: 2rem;
  display: inline-block;
  height: 32px;
  cursor: pointer;
`
