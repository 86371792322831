const ButtonTypeEnum = Object.freeze({
  primary: 1,
  secondary: 2,
  lightBlue: 3,
  blue: 4,
  black: 5,
  white: 6,
})

export default ButtonTypeEnum
