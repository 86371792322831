import React from "react"
import PageBanner from "components/ui/PageBanner"
import { Col, Container, Row } from "reactstrap"
import { Zoom, Slide, Fade } from "react-reveal"
import Image from "components/common/Image"
import SectionTitle from "components/common/SectionTitle"
import SectionContent from "components/common/SectionContent"
import ExpandableContent from "components/common/ExpandableContent"
import ColouredContent from "components/common/ColouredContent"
import SmallQuoteAbsolute from "components/common/SmallQuoteAbsolute"
import Quote from "components/common/Quote"
import NextPage from "components/common/NextPage"

function CreatingHomes({ bannerImage, slug }) {
  return (
    <>
      <PageBanner
        src={bannerImage}
        alt="Creating Homes, Building Futures"
        slug={slug}
      />
      <Container className="mt-5">
        <Row className="align-items-center">
          <Col xs="12" lg="6">
            <Slide left>
              <h3>
                The external environment is complex, fast changing and
                uncertain. There is a housing crisis facing our country, with a
                focus of Government to build more homes and to fix the broken
                housing market.
              </h3>
              <p>
                There is a high level of social deprivation across parts of the
                Tees Valley and County Durham. There remains a continuing and
                much needed requirement for good quality social and affordable
                rented accommodation. We remain committed to solving the housing
                crisis and sustaining communities.
              </p>
            </Slide>
          </Col>
          <Col xs="12" lg="6" className="text-center">
            <Zoom>
              <Image
                src={require("assets/images/external-enviro.png")}
                alt="Creating Enviro"
                loadingType="eager"
                alignCenter={true}
              />
            </Zoom>
          </Col>
        </Row>
      </Container>
      <SectionTitle
        colour="orange"
        title="We invest in Communities, neighbourhoods and people to enable them to thrive"
      />
      <SectionContent>
        <Row>
          <Col xs="12" md="6">
            <p>
              During the year North Star continued to invest in communities. Our
              modest investment of £61,549 attracted £90k of funding from other
              sources. All of our activity throughout the year generated over
              £2m in social value and directly benefitted over 2,500 people
            </p>
            <p>
              Covid-19 caused huge amounts of disruption and worry for
              communities across the country. According to the British Red
              Cross’ Covid Vulnerability Index the majority of communities that
              North Star works in were most likely to see significant impacts
              both from the virus itself and also, the impact of lockdown
              conditions on health and employment.
            </p>
          </Col>
          <Col xs="12" md="6">
            <p>
              To support the fantastic efforts of grass roots groups and
              volunteers across the North East region North Star made and
              additional <strong>£20k</strong> of funding available. These
              groups provided help in sourcing and delivering food, help to
              deliver prescription to those most at risk and to keeping vital
              local services such as Post Offices operating in rural locations.
              We got money to them fast and helped them to identify those who
              needed the help the most.
            </p>
            <p>Here are just a few stories from organisations we supported:</p>
          </Col>
        </Row>
      </SectionContent>
      <Container>
        <Row>
          <Col xs="12" lg="6" className="mt-5">
            <ExpandableContent
              imgSrc={require("assets/images/orangehex1.png")}
              title="Butterknowle"
              colour="orange"
            >
              <p>
                <strong>Butterknowle</strong>
                <br />
                We supported volunteers in Butterknowle, County Durham to
                provide help to residents of the village. One volunteer told us:
                “Living in a rural village with no local shop, I wanted to
                provide support to those most in need. After approaching North
                Star Housing I was awarded funding to provide ‘community
                wellness packs’. These packs consisted of essential foods and
                toiletries such as toilet roll, milk, bread, butter, veg, soup
                etc.
              </p>
              <p className="mb-0">
                The project then progressed to provide home cooked meals to the
                vulnerable people living in Butterknowle and the surrounding
                areas. At the very peak of the virus we were{" "}
                <strong>supporting over 80 people</strong> in our small rural
                area, providing them with nutritious home cooked meals as well
                as other essential items and of course some one to regularly
                talk to. “Just when I thought North Star Housing couldn’t get
                any better, they offered, sourced and funded takeaway boxes to
                enable us to continue safely distribute of meals. It is a great
                comfort to know that North Star is committed to supporting our
                rural communities and they have done a grand job at showing this
                support, particularly over the last few months when we have
                needed them most.”
              </p>
            </ExpandableContent>
          </Col>
          <Col xs="12" lg="6" className="mt-5">
            <ExpandableContent
              imgSrc={require("assets/images/orangehex2.png")}
              title="Little Sprouts CiC"
              colour="orange"
            >
              <p>
                <strong>Little Sprouts CiC</strong>
                <br />
                Little Sprouts CiC, Groundwork North East and Stockton Borough
                Council to provide a range services to the local residents. We
                supported this project with funding and made the newly
                refurbished Community Hub building available for use as a food
                distribution centre. This hub has emerged as an essential
                service supporting families who may otherwise have been
                overlooked. The service extended beyond food, offering lap tops
                and mobile phones to help people reach services that they needed
                but couldn’t access.
              </p>
              <Image
                src={require("assets/images/little-sprouts-bottom-image.png")}
                alt="Little Sprouts CiC 2"
                alignCenter={true}
              />
            </ExpandableContent>
          </Col>
        </Row>
      </Container>
      <SectionTitle
        colour="orange"
        title="We use our financial strength to help resolve the housing crisis"
      />
      <SectionContent>
        <Row className="align-items-center">
          <Col xs="12" sm="6">
            <p>
              We are proud to use our financial strength to build new homes and
              help solve the housing crisis. The new housing that we deliver
              provides local people with high quality and fuel efficient,
              affordable housing.
            </p>
          </Col>
          <Col xs="12" sm="6">
            <Image
              src={require("assets/images/finance-image.png")}
              alt="Financial Strength"
              alignCenter={true}
            />
          </Col>
        </Row>
      </SectionContent>
      <Container className="mt-5">
        <Row>
          <Col xs="12" lg="6" className="mb-minus-100">
            <Slide left>
              <div>
                <Image
                  src={require("assets/images/elwick-image.png")}
                  alt="Hartlepool"
                  style={{ position: "relative", zIndex: 20 }}
                  alignCenter={true}
                />
                <ColouredContent
                  colour="orange"
                  quote="We absolutely love our new home it’s our first home. We love it."
                  className="mt-0"
                >
                  <p>
                    <strong>Elwick Road, Hartlepool</strong>
                  </p>
                  <p className="mb-0">
                    On the site of the former Caroline Street Health Clinic our
                    new houses and bungalows have improved an untidy area of
                    wasteland. 11, two and three bedroomed homes were built in
                    this popular location with plenty of amenities within
                    walking distance.
                  </p>
                </ColouredContent>
              </div>
            </Slide>
          </Col>
          <Col xs="12" lg="6" className="mb-minus-100">
            <Slide right>
              <div>
                <Image
                  src={require("assets/images/lingfield-image.png")}
                  alt="Darlington"
                  style={{ position: "relative", zIndex: 20 }}
                  alignCenter={true}
                />
                <ColouredContent
                  colour="dark-grey"
                  quote="We are so very happy living here."
                  className="mt-0"
                >
                  <p>
                    <strong>Lingfield Point, Darlington</strong>
                  </p>
                  <p className="mb-0">
                    Over five years we have acquired 41 new homes, which has
                    transformed former industrial land on the outskirts of
                    Darlington. This year we helped to finalise the development,
                    bringing a final four new homes to the market.
                  </p>
                </ColouredContent>
              </div>
            </Slide>
          </Col>
        </Row>
        <Row>
          <Col className="d-none d-lg-block" style={{ alignSelf: "center" }}>
            <Fade bottom>
              <Image
                src={require("assets/images/claireville.png")}
                alt="Claireville"
              />
            </Fade>
          </Col>
          <Col xs="12" lg="6">
            <Fade bottom>
              <ColouredContent colour="orange">
                <p>
                  <strong>Clairville Grange, Middlesbrough</strong>
                </p>
                <p className="mb-5">
                  We bought six new homes in this popular area on the site of
                  the former Clairville Stadium.
                </p>
                <Quote text="We absolutely love our new home it’s our first home. We love it." />
              </ColouredContent>
            </Fade>
          </Col>
          <Col className="d-none d-lg-block">
            <Fade bottom>
              <Image
                src={require("assets/images/househex.png")}
                alt="House"
                alignCenter={true}
                className="mt-5"
              />
            </Fade>
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="6" className="mt-minus-100">
            <ColouredContent colour="dark-grey" style={{ minHeight: 300 }}>
              <p>
                <strong>Heighington Meadows, Darlington</strong>
              </p>
              <p>
                We acquired five new homes on this very desirable new
                development in Heighington Village. All new homes were let to
                people with a local connection to the village. Its great to be
                able to provide affordable housing for local people
              </p>
            </ColouredContent>
            <div style={{ zIndex: 100, position: "relative" }}>
              <Fade bottom>
                <Image
                  src={require("assets/images/heighton.png")}
                  alt="House"
                  alignCenter={true}
                  style={{ marginTop: -100 }}
                />
              </Fade>
            </div>
          </Col>
          <Col xs="12" lg="6" className="position-relative mt-minus-100">
            <SmallQuoteAbsolute
              text="Amazing, it's gorgeous, we are so delighted. My son and I love it."
              style={{ top: -35, right: 35 }}
              className="d-none d-block-lg"
            />
            <ColouredContent colour="orange" style={{ minHeight: 300 }}>
              <p>
                <strong>Mill Race, West Auckland, Durham</strong>
              </p>
              <p>
                Fronting on to the Conservation Area of East Green but latterly
                occupied by an empty nursing home and derelict land, this high
                quality development in the heart of the village comprises 8 two
                bedroomed bungalows and 17 two and three bedroomed houses. The
                naming of the development reflects an historic land use in the
                immediate area.
              </p>
            </ColouredContent>
            <div style={{ zIndex: 100, position: "relative" }}>
              <Fade bottom>
                <Image
                  src={require("assets/images/mill-race.png")}
                  alt="House"
                  alignCenter={true}
                  style={{ marginTop: -100 }}
                />
              </Fade>
            </div>
          </Col>
        </Row>
      </Container>
      <SectionTitle colour="orange" title="We improve homes" />
      <SectionContent isPointVisible={false}>
        <Row className="mb-2">
          <Col xs="12" lg="6">
            <p>
              Each year we improve tenants homes and neighbourhoods. This
              ensures that people live in safe, warm and well maintained houses.
            </p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <h3 className="mb-4">Improvements during the year</h3>
            <Image
              src={require("assets/images/orange-graph.png")}
              alt="Work Improvments"
              alignCenter={true}
            />
          </Col>
        </Row>
      </SectionContent>
      <NextPage slug="delivering-impact" />
    </>
  )
}

export default CreatingHomes
