import { createGlobalStyle, css } from "styled-components"
import vars from "styles/variables"

const GlobalStyle = css`
  body {
    margin: 0;
    font-family: ${vars.primaryFont};
    background: ${vars.white};
    color: ${vars.textColour};
    line-height: 1.4;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  main {
    flex-grow: 1;
  }

  h1,
  h2,
  h3 {
    margin-top: 0;
    font-weight: 600;
  }

  h2 {
    font-size: 2rem;
    @media (max-width: ${vars.screen_xs_max}) {
      font-size: 1.5rem;
    }
  }

  h3 {
    font-size: 1.4rem;
    @media (max-width: ${vars.screen_xs_max}) {
      font-size: 1.2rem;
    }
  }

  a {
    text-decoration: none;
    color: ${vars.black};
  }

  p {
    margin-top: 0;
    line-height: 1.8;
  }

  ul {
    line-height: 1.8;
  }

  @media (min-width: ${vars.screen_md_min}) {
    .mt-minus-100 {
      margin-top: -100px;
    }
    .mb-minus-100 {
      margin-bottom: -100px;
    }
  }
`

export default createGlobalStyle`
    ${GlobalStyle}
`
