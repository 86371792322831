import React from "react"
import styled from "styled-components"
import { Fade, Flip } from "react-reveal"
import vars from "styles/variables"
import { getColour } from "utils/styleUtil"

function ColouredContent({ colour, showTop, children, quote, ...rest }) {
  return (
    <>
      <StyledColouredContent colour={colour} showTop={showTop} {...rest}>
        <div>
          <Fade bottom>{children}</Fade>
        </div>
      </StyledColouredContent>
      {quote && (
        <StyledQuote>
          <Flip right>
            <img
              src={require("assets/icons/quote-left.svg")}
              alt=""
              className="quote-left"
            />
            {quote}
            <img
              src={require("assets/icons/quote-right.svg")}
              alt=""
              className="quote-right"
            />
          </Flip>
        </StyledQuote>
      )}
    </>
  )
}

export default ColouredContent

const StyledColouredContent = styled.div`
  padding: 1rem 2rem 0.5rem 2rem;
  background: ${props => getColour(props.colour)};
  ${props => props.colour && `color: ${vars.white};`}
  border-radius: 30px;
  text-align: center;
  position: relative;
  margin: 150px 0;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  @media (max-width: ${vars.screen_xs_max}) {
    margin: 110px 0;
  }

  &:before,
  &:after {
    content: "";
    background: ${props => getColour(props.colour)};
    width: calc(100% - 20px);
    height: 150px;
    display: block;
    position: absolute;
    left: 10px;
    border-radius: 20px;
    @media (max-width: ${vars.screen_xs_max}) {
      height: 110px;
    }
  }

  &:before {
    top: -143px;
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
    @media (max-width: ${vars.screen_xs_max}) {
      top: -103px;
    }
  }

  &:after {
    bottom: -143px;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    @media (max-width: ${vars.screen_xs_max}) {
      bottom: -103px;
    }
  }
`
const StyledQuote = styled.div`
  background: ${vars.grey_200};
  padding: 13rem 5rem 0.5rem 5rem;
  text-align: center;
  margin-bottom: 150px;
  position: relative;
  border-radius: 30px;
  margin-top: -185px;
  font-size: 1.5rem;
  position: relative;

  @media (max-width: ${vars.screen_xs_max}) {
    margin-bottom: 110px;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  img {
    position: absolute;
    z-index: 20;
    @media (max-width: ${vars.screen_xs_max}) {
      width: 20px;
    }

    &.quote-left {
      left: 30px;
      @media (max-width: ${vars.screen_xs_max}) {
        left: 10px;
      }
    }

    &.quote-right {
      right: 30px;
      bottom: 15px;
      @media (max-width: ${vars.screen_xs_max}) {
        right: 10px;
      }
    }
  }

  &:after {
    content: "";
    background: ${props => getColour(props.colour)};
    width: calc(100% - 20px);
    height: 150px;
    display: block;
    position: absolute;
    left: 10px;
    border-radius: 20px;
    bottom: -143px;
    clip-path: polygon(50% 100%, 0 0, 100% 0);

    @media (max-width: ${vars.screen_xs_max}) {
      height: 110px;
      bottom: -103px;
    }
  }
`
