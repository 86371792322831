import React from "react"
import styled from "styled-components"
import vars from "styles/variables"

function SmallQuoteAbsolute({ text, ...rest }) {
  return (
    <StyledSmallQuoteAbsolute {...rest}>
      <img
        src={require("assets/icons/quote-left.svg")}
        alt=""
        className="quote-left"
      />
      {text}
      <img
        src={require("assets/icons/quote-right.svg")}
        alt=""
        className="quote-right"
      />
    </StyledSmallQuoteAbsolute>
  )
}

export default SmallQuoteAbsolute

const StyledSmallQuoteAbsolute = styled.div`
  position: absolute;
  width: 220px;
  z-index: 30;
  background: ${vars.grey_200};
  font-size: 1.2rem;
  padding: 0.5rem 1.5rem;
  text-align: center;
  border-radius: 30px;

  img {
    position: absolute;
    z-index: 30;
    left: calc(50% - 26px);

    &.quote-left {
      top: -40px;
    }

    &.quote-right {
      bottom: -40px;
    }
  }

  &:before,
  &:after {
    content: "";
    background: ${vars.grey_200};
    width: calc(100% - 20px);
    height: 80px;
    display: block;
    position: absolute;
    left: 10px;
    border-radius: 20px;
  }

  &:before {
    top: -72px;
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
  }

  &:after {
    bottom: -72px;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
  }
`
